const Overlay = require('app/component/Overlay');
const FileDropZone = require('app/ui/form/FileDropZone');

class Form {

    defaultOptions() {
        return {
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onClickBack = this.onClickBack.bind(this);
        this.onClickBtnStepPrevious = this.onClickBtnStepPrevious.bind(this);
        this.onClickBtnStepNext = this.onClickBtnStepNext.bind(this);

        this.coordinates = null;
        this.longitude = null;
        this.latitude = null;
        this.step = 0;
        this.lastStep = 6;

        this.initialize();
    }

    initialize() {
        var self = this;

        this.$inputLatitude = $('<input/>')
        .attr('id','latitude')
        .attr('name','latitude')
        .attr('type','text')
        .attr('required',"required")
        .addClass('form-control')
        .val(this.latitude);

        this.$inputLongitude = $('<input/>')
        .attr('name','longitude')
        .attr('type','text')
        .attr('required',"required")
        .addClass('form-control')
        .val(this.longitude);

        this.$btnRelocalise = $('<button/>')
        .attr('type','button')
        .addClass('btn btn-beige border-orange btn-relocalize')
        .append(window.translate('relocalize'))
        .append(
            $('<i/>')
            .addClass('icon icon-localisation')
        )
        .on('click',function(event){
            event.stopPropagation();
            event.preventDefault();

            self.hide();
            self.reset();
        });

        this.$inputDate = $('<input/>')
        .attr("type","date")
        .attr("id","date")
        .attr("name","date")
        .attr('required',"required")
        .addClass("form-control")
        .val(moment().format('YYYY-MM-DD'));

        this.$inputHour = $('<input/>')
        .attr("type","time")
        .attr("id","hour")
        .attr("name","hour")
        .attr('required',"required")
        .addClass("form-control")
        .val(moment().format('HH:mm'));


        this.$choiceBinocular = $('<label/>')
        .addClass('choice')
        .attr("data-choice","binocular")
		.append(
			$("<i/>")
			.addClass('icon icon-jumelle')
		)
        .append(
            $('<input/>')
            .attr("type","radio")
            .addClass('form-control form-radio')
            .attr("id","binocular")
            .attr("name","how")
            .val("Jumelles")
        )
        .append(window.translate('binocular'));

        this.$choiceTelescope = $('<label/>')
        .addClass('choice')
        .attr("data-choice","telescope")
		.append(
			$("<i/>")
			.addClass('icon icon-longue_vue')
		)
        .append(
            $('<input/>')
            .attr("type","radio")
            .addClass('form-control form-radio')
            .attr("id","telescope")
            .attr("name","how")
            .val("Longue vue")
        )
        .append(window.translate('telescope'));

        this.$choiceNakedEye = $('<label/>')
        .addClass('choice')
        .attr("data-choice","nakedeye")
		.append(
			$("<i/>")
			.addClass('icon icon-oeil')
		)
        .append(
            $('<input/>')
            .attr("type","radio")
            .addClass('form-control form-radio')
            .attr("id","nakedeye")
            .attr("name","how")
            .val("Oeil nu")
        )
        .append(window.translate('nakedeye'));

        this.$selectRightEar = $('<select/>')
        .attr("id","rightear")
        .attr('name',"rightear")
        .attr('required',"required")
        .addClass('form-control');

        let colorsRight = [
			"pas_de_boucle",
			"pas_vu_couleur",
            "blanc",
            "bleu",
            "jaune",
            "orange",
            "parme",
            "rose",
            "violet",
            "rouge",
            "vert"
        ];
		this.$selectRightEar
		.append(
			$('<option/>')
			.val('')
			.append(window.translate("select"))
		)
		for(var c of colorsRight){
			this.$selectRightEar
			.append(
				$('<option/>')
				.val(c)
				.append(window.translate(c))
			)
		}

        this.$selectLeftEar = $('<select/>')
        .attr("id","leftear")
        .attr('name',"leftear")
        .attr('required',"required")
        .addClass('form-control');

        let colorsLeft = [
			"pas_de_boucle",
			"pas_vu_couleur",
            "blanc",
            "bleu",
            "jaune",
            "orange",
            "parme",
            "rose",
            "violet",
            "rouge",
            "vert",
			"vert_jaune",
			"jaune_vert",
			"jaune_rouge",
			"blanc_rouge"
        ];
		this.$selectLeftEar
		.append(
			$('<option/>')
			.val('')
			.append(window.translate("select"))
		)
		for(var c of colorsLeft){
			this.$selectLeftEar
			.append(
				$('<option/>')
				.val(c)
				.append(window.translate(c))
			)
		}
        
        this.$selectHeadband = $('<select/>')
        .attr("id","headband")
        .attr('name',"headband")
        .attr('required',"required")
        .addClass('form-control');

        let colorsHeadband = [
			"pas_de_bandeau",
			"pas_vu_couleur",
            "blanc",
            "bleu",
            "jaune",
            "orange",
            "violet",
            "rouge",
            "vert"
        ];
		this.$selectHeadband
		.append(
			$('<option/>')
			.val('')
			.append(window.translate("select"))
		)
		for(var c of colorsHeadband){
			this.$selectHeadband
			.append(
				$('<option/>')
				.val(c)
				.append(window.translate(c))
			)
		}
        
        this.$selectNeckless = $('<select/>')
        .attr("id","neckless")
        .attr('name',"neckless")
        .attr('required',"required")
        .addClass('form-control');

        let colorsNeckless = [
			"pas_de_collier",
			"pas_vu_couleur",
            "blanc",
            "bleu",
            "jaune",
            "orange",
            "violet",
            "rouge",
            "vert"
        ];
		this.$selectNeckless
		.append(
			$('<option/>')
			.val('')
			.append(window.translate("select"))
		)
		for(var c of colorsNeckless){
			this.$selectNeckless
			.append(
				$('<option/>')
				.val(c)
				.append(window.translate(c))
			)
		}

        this.selects = [
            this.$selectRightEar,
            this.$selectLeftEar,
            this.$selectHeadband,
            this.$selectNeckless,
        ];


        this.fileDropZone = new FileDropZone({
            '$label': '<span>+</span>' + window.translate('observation.form.step5.upload'),
            'inputName': 'files[]',
        })
        this.$inputFiles = this.fileDropZone.getInput();

        this.$inputExternalLink =  $('<input/>')
        .attr("type","text")
        .attr("name","externalUrl")
        .attr('placeholder',window.translate('observation.form.step5.bylink.placeholder'))
        .addClass("form-control");

        this.$inputFirstName = $('<input/>')
        .attr("type","text")
        .attr("name","firstName")
        .addClass("form-control");

        this.$inputLastName = $('<input/>')
        .attr("type","text")
        .attr("name","lastName")
        .addClass("form-control");

        this.$inputCompany = $('<input/>')
        .attr("type","text")
        .attr("name","company")
        .addClass("form-control");

        this.$inputEmail = $('<input/>')
        .attr("type","email")
        .attr("name","email")
        .attr('required',"required")
		.attr('placeholder','votremail@mail.fr')
        .addClass("form-control");


        this.$inputAddress = $('<input/>')
        .attr("type","text")
        .attr("name","address")
        .addClass("form-control");

        this.$inputZip = $('<input/>')
        .attr("type","text")
        .attr("name","zip")
		.attr('placeholder','09000')
        .addClass("form-control");

        this.$inputCity = $('<input/>')
        .attr("type","text")
        .attr("name","city")
        .addClass("form-control");

        this.$inputComment = $('<textarea/>')
        .attr("name","comment")
		.attr("id","comment")
        .addClass("form-control")
		.val(window.translate('observation.form.step6.comment.value'));

        this.build();

		this.onClickStep3();
    }

    build(){
        this.overlay = new Overlay();

        this.buildForm();

        this.$infoContainer = $('<div/>')
        .addClass('form-observation-info-container');

        this.$container = $('<div/>')
        .addClass('form-observation-container')
        .append(this.$infoContainer)
        .append(this.$form)

        this.overlay.setContent(this.$container);
    }

    reset(){
        this.coordinates = null;
        this.longitude = null;
        this.latitude = null;
        this.showStep(0);

        this.$form.show();
        this.$infoContainer.html('');
    }

    showStep(step){
        this.step = step;
        this.$infoContainer.html('');
        $('.step-list-item',this.$stepList).removeClass('show');
        $('.step-list-item[data-step="'+step+'"]',this.$stepList).addClass('show');
    }

    buildForm(){

        this.$form = $('<form/>')
        .attr('method','POST')
        .attr('action',window.App.url('api.observation-submit'))
        .addClass('form form-observation');

        this.$btnBack = $('<button/>')
        .addClass('btn btn-link btn-back')
        .append(
            $('<i/>')
            .addClass('icon icon-arrow-left')
        )
        .append(window.translate('observation.form.back'))
        .on('click',this.onClickBack);

        this.$header = $('<div/>')
        .addClass('form-header')
        .append(this.$btnBack)

        this.$stepList = $('<div/>')
        .addClass('step-list');

        this.fillStepList();

        this.$form
        .append(this.$header)
        .append(this.$stepList);

    }

    fillStepList(){
        this.$step0 = $('<div/>')
        .addClass('step-list-item show')
        .attr('data-step', 0)
        .append(this.buildStep0())
        .appendTo(this.$stepList);

        this.$step1 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 1)
        .append(this.buildStep1())
        .appendTo(this.$stepList);

        this.$step2 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 2)
        .append(this.buildStep2())
        .appendTo(this.$stepList);

        this.$step3 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 3)
        .append(this.buildStep3())
        .appendTo(this.$stepList);

        this.$step4 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 4)
        .append(this.buildStep4())
        .appendTo(this.$stepList);

        this.$step5 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 5)
        .append(this.buildStep5())
        .appendTo(this.$stepList);

        this.$step6 = $('<div/>')
        .addClass('step-list-item')
        .attr('data-step', 6)
        .append(this.buildStep6())
        .appendTo(this.$stepList);
    }

    buildProgress(step){
        var self = this;

        let $progress = $('<div/>')
        .addClass('step-progress');

        let $list = $('<ul/>')
        .addClass('progress-list');

        for(var i = 0; i <= this.lastStep; i++){
            let $bullet = $("<span/>")
            .addClass('progress-bullet');

            let $item = $('<li/>')
            .attr('data-step',i)
            .addClass('progress-list-item')
            .append($bullet)

            if(i == step){
                $bullet.addClass('active')
            }else{
                $item.on('click',function(){
                    self.showStep($(this).data('step'));
                });
            }


            $list.append($item);
        }

        $progress.append($list);

        return $progress;
    }

    buildStepNav(step, btnPreviousContent, btnNextContent){
        let $nav = $('<div/>')
        .addClass('step-nav btn-group');

        let $btnPrevious = $('<button/>')
        .attr("type","button")
        .addClass('btn btn-beige btn-step-previous')
        .on('click',this.onClickBtnStepPrevious)
        .append(btnPreviousContent);

        let $btnNext = $('<button/>')
        .attr("type","button")
        .addClass('btn btn-step-next')
        .on('click',this.onClickBtnStepNext)
        .append(btnNextContent);
        if(step < 6){
            $btnNext.addClass('btn-orange');
        }else{
            $btnNext.addClass('btn-green');


            this.$btnSubmit = $btnNext;
        }


        $nav
        .append($btnPrevious)
        .append($btnNext);

        return $nav;

    }

    buildStep0(){
        let step = 0;
        let $content = $('<div/>')
        .addClass('step step-0')
        .attr('data-step',step);

        let $image = $('<div/>')
        .addClass('step-image')
        .append(
            $('<img/>')
            .attr('src','/build/img/observation/form/step0.png')
        )

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(window.translate('observation.form.step0.title'));

        let $sentence = $('<p/>')
        .addClass('step-sentence')
        .append(window.translate('observation.form.step0.sentence'));

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-localisation"></i> '+window.translate('observation.form.step0.previous.text');
        let btnNextContent = window.translate('observation.form.step0.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);


        $content
        .append($image)
        .append($title)
        .append($sentence)
        .append($progress)
        .append($nav)

        return $content;
    }

    buildStep1(){
        let step = 1;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('01')
        )
        .append(window.translate('observation.form.step1.title'));


        let $subtitle = $('<h4/>')
        .append(window.translate('observation.form.step1.subtitle'))


        let $coordinatesRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputLatitude.attr('id'))
                .append(window.translate('latitude'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$inputLatitude)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputLongitude.attr('id'))
                .append(window.translate('longitude'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$inputLongitude)
        )


        let $relocalizeContainer = $('<div/>')
        .append(
            $('<h4/>')
            .append(window.translate('or'))
        )
        .append(
            this.$btnRelocalise
        );

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step1.previous.text');
        let btnNextContent = window.translate('observation.form.step1.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);

        $content
        .append($title)
        .append($subtitle)
        .append($coordinatesRow)
        .append($relocalizeContainer)
        .append($progress)
        .append($nav);

        return $content;
    }

    buildStep2(){
        let step = 2;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('02')
        )
        .append(window.translate('observation.form.step2.title'));

        let $whenRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group form-date')
            .append(
                $('<label/>')
                .attr('for',this.$inputDate.attr('id'))
                .append(window.translate('date') + ' :')
            )
            .append(this.$inputDate)
        )
        .append(
            $('<div/>')
            .addClass('col form-group form-hour')
            .append(
                $('<label/>')
                .attr('for',this.$inputHour.attr('id'))
                .append(window.translate('hour') + ' :')
            )
            .append(this.$inputHour)
        )

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step2.previous.text');
        let btnNextContent = window.translate('observation.form.step2.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);

        $content
        .append($title)
        .append($whenRow)
        .append($progress)
        .append($nav);

        return $content;
    }

    buildStep3(){
        let step = 3;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('03')
        )
        .append(window.translate('observation.form.step3.title'));

        let $progress = this.buildProgress(step);

        let $howRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group form-group-radio checked')
            .append(this.$choiceBinocular)
        )
        .append(
            $('<div/>')
            .addClass('col form-group form-group-radio')
            .append(this.$choiceTelescope)
        )
        .append(
            $('<div/>')
            .addClass('col form-group form-group-radio')
            .append(this.$choiceNakedEye)
        )

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step3.previous.text');
        let btnNextContent = window.translate('observation.form.step3.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);

        $content
        .append($title)
        .append($howRow)
        .append($progress)
        .append($nav);
        
        return $content;
    }

    buildStep4(){
        let step = 4;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('04')
        )
        .append(window.translate('observation.form.step4.title'));

        let $image = $('<div/>')
        .addClass('step-image')
        .append(
            $('<img/>')
            .attr('src','/build/img/observation/form/step4.png')
        )

        let $earRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$selectRightEar.attr('id'))
                .append(window.translate('rightear'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$selectRightEar)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$selectLeftEar.attr('id'))
                .append(window.translate('leftear'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$selectLeftEar)
        )

        let $otherRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$selectHeadband.attr('id'))
                .append(window.translate('headband'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$selectHeadband)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$selectNeckless.attr('id'))
                .append(window.translate('neckless'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$selectNeckless)
        )

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step4.previous.text');
        let btnNextContent = window.translate('observation.form.step4.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);

        $content
        .append($title)
        .append($image)
        .append($earRow)
        .append($otherRow)
        .append($progress)
        .append($nav);

        return $content;
    }

    buildStep5(){
        let step = 5;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('05')
        )
        .append(window.translate('observation.form.step5.title'));



        let $dropZoneContainer = this.fileDropZone.getContainer();

        console.log("$dropZoneContainer ",$dropZoneContainer);


        $dropZoneContainer
        .append(
            $('<div/>')
            .addClass('helper')
            .append(window.translate('observation.form.step5.warning1'))
        )
        .append(
            $('<div/>')
            .addClass('helper')
            .append(window.translate('observation.form.step5.warning2'))
        );

        let $byLinkContainer = $('<div/>')
        .addClass('')
        .append(
            $('<h4/>')
            .append(window.translate('observation.form.step5.bylink.title'))
        )
        .append(
            $('<h3/>')
            .append(window.translate('observation.form.step5.bylink.sentence'))
        )
        .append(
            $('<div/>')
            .append(
                $('<label/>')
                .append(window.translate('observation.form.step5.bylink.label'))
            )
            .append(this.$inputExternalLink)
        )

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step5.previous.text');
        let btnNextContent = window.translate('observation.form.step5.next.text') + ' <i class="icon icon-arrow-right"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);

        $content
        .append($title)
        .append($dropZoneContainer)
        .append($byLinkContainer)
        .append($progress)
        .append($nav);

        return $content;
    }

    buildStep6(){
        let step = 6;
        let $content = $('<div/>')
        .addClass('step step-'+step)
        .attr('data-step',step);

        let $title = $('<h2/>')
        .addClass('step-title')
        .append(
            $('<span/>')
            .addClass('step-number')
            .append('06')
        )
        .append(window.translate('observation.form.step6.title'));

        let $firstRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputLastName.attr('id'))
                .append(window.translate('lastName'))
            )
            .append(this.$inputLastName)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputFirstName.attr('id'))
                .append(window.translate('firstName'))
            )
            .append(this.$inputFirstName)
        )

        let $secondRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputCompany.attr('id'))
                .append(window.translate('company'))
            )
            .append(this.$inputCompany)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputEmail.attr('id'))
                .append(window.translate('email'))
                .append(
                    $('<span/>')
                    .addClass('required-mark text-orange')
                    .append('*')
                )
            )
            .append(this.$inputEmail)
        )

        let $thirdRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputAddress.attr('id'))
                .append(window.translate('address'))
            )
            .append(this.$inputAddress)
        )

        let $fourthRow = $('<div/>')
        .addClass('row')
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputZip.attr('id'))
                .append(window.translate('zip'))
            )
            .append(this.$inputZip)
        )
        .append(
            $('<div/>')
            .addClass('col form-group')
            .append(
                $('<label/>')
                .attr('for',this.$inputCity.attr('id'))
                .append(window.translate('city'))
            )
            .append(this.$inputCity)
        )

		let $comment = 	$('<div/>')
        .addClass('row')
        .append(
			$('<div/>')
			.addClass('col form-group')
			.append(
				$('<label/>')
				.attr('for',this.$inputComment.attr('id'))
				.append(window.translate('observation.form.step6.comment.label'))
				.append($('<br/>'))
				.append(
					$("<span/>")
					.addClass('helper')
					.append(window.translate('observation.form.step6.comment.span'))
				)
			)
			.append(this.$inputComment)
		);

		let $legals = $('<div/>')
		.addClass("legals row")
        .append(
			$("<div/>")
			.addClass('col')
			.append(
				$('<p/>')
				.append(window.translate('observation.form.step6.legal1'))
			)
			.append(
				$('<p/>')
				.append(window.translate('observation.form.step6.legal2'))
			)
			.append(
				$('<p/>')
				.append(window.translate('observation.form.step6.legal3'))
			)
			.append(
				$('<p/>')
				.append(window.translate('observation.form.step6.legal4'))
			)
		);

        let $progress = this.buildProgress(step);

        let btnPreviousContent = '<i class="icon icon-arrow-left"></i> '+window.translate('observation.form.step6.previous.text');
        let btnNextContent = window.translate('observation.form.step6.next.text') + ' <i class="icon icon-check"></i>';
        let $nav = this.buildStepNav(step, btnPreviousContent,btnNextContent);


        $content
        .append($title)
        .append($firstRow)
        .append($secondRow)
        .append($thirdRow)
        .append($fourthRow)
        .append($comment)
        .append($legals)
        .append($progress)
        .append($nav);

        return $content;
    }

    setCoordinates(coordinates){
        //@todo improve validation of coordinates (string like '26.54654,56.24654')
        let data = coordinates.split(',');

        this.coordinates = data;
        this.latitude = this.coordinates[0];
        this.longitude = this.coordinates[1];

        this.resetInputs();
    }

    resetInputs(){
        console.log("reset inputs");
        this.$inputLatitude.val(this.latitude);
        this.$inputLongitude.val(this.longitude);

        this.$inputDate.val(moment().format('YYYY-MM-DD'));
        this.$inputHour.val(moment().format('HH:mm'));

        this.$choiceBinocular.find('input').prop('checked',true);
        this.$choiceTelescope.find('input').prop('checked',false);
        this.$choiceNakedEye.find('input').prop('checked',false);

        for(var i in this.selects){
            let $select = this.selects[i];
            $select.val('').trigger('change');
        }

        this.fileDropZone.reset();
    }

    onClickBack(event){
        event.preventDefault();
        event.stopPropagation();
        this.hide();
        this.reset();
    }

    onClickBtnStepPrevious(){
        if(this.step > 0){
            this.showStep(this.step-1);

			$('.overlay').animate({
				scrollTop: 0
			}, 1000);
        }else{
            this.hide();
            this.reset();
        }
    }

    onClickBtnStepNext(){
        let isStepValid = false;
        switch(this.step){
            case 0:
                //nothing to check
                isStepValid = true;
            break;

            case 1:
                //@todo check if this.$inputLatitude and this.$inputLongitude are valid
				if(this.$inputLatitude.val() == '' || this.$inputLongitude.val() == ''){
					isStepValid = false
				}else{
                	isStepValid = true;
				}
            break;

            case 2:
                //@todo check if this.$inputDate and this.$inputHour are valid
				if(this.$inputDate.val() == '' || this.$inputHour.val() == ''){
					isStepValid = false
				}else{
                	isStepValid = true;
				}
            break;

            case 3:
                isStepValid = true;
            break;

            case 4:
                if(
					this.$selectLeftEar.val() == ''
					|| this.$selectRightEar.val() == ''
					|| this.$selectHeadband.val() == ''
					|| this.$selectNeckless.val() == ''
				){
					isStepValid = false
				}else{
                	isStepValid = true;
				}
            break;

            case 5:
                isStepValid = true;
            break;

            case 6:
                if(this.$inputEmail.val() == ''){
					isStepValid = false
				}else{
                	isStepValid = true;
				}
            break;
        }

		$(".message-input-error").remove();
		$(".required").removeClass('required');
		
        if(isStepValid){

            if(this.step < this.lastStep){
                this.showStep(this.step+1);
            }else{
                this.submit();
            }

			$('.overlay').animate({
				scrollTop: 0
			}, 1000);
        }else{
			var formObject = this.$form[0];
			var scrolled = false;
			$( ':input[required]', formObject ).each( function () {
				var elem = $(this);
				if ( this.value.trim() !== '' ) {
					elem.removeClass('required');
				}else{
					elem.addClass('required');
					elem.after(
						$("<div/>")
						.addClass('message-input-error')
						.append(window.translate('contact.submit.errorFields') + ' <strong>' + elem.parent().find('label').html() + '</strong>')
					);
					if(scrolled === false){
						$('.overlay').animate({
							scrollTop: elem.offset().top
						}, 1000);
						scrolled = true;
					}
				}
			});

            return false;
        }
    }


    isValid(){
        var formObject = this.$form.get(0);
        var validity = formObject.checkValidity();

        this.$form.addClass('was-validated');

        if(validity === false){
            //event.stopPropagation();
            return false;
        }
        return true;
    }

    submit(){
        var self = this;

        this.$infoContainer
        .html('')

		$(".message-input-error").remove();
		$(".required").removeClass('required');

        if(this.isValid()){


            this.$btnSubmit.attr('disabled','disabled');
            var formObject = this.$form.get(0);
            var formData = new FormData(formObject);

            $.ajax({
                'url': this.$form.attr('action'), // the endpoint
                'type': this.$form.attr('method'), // http method
                'processData': false,
                'contentType': false,
                'data': formData,
            })
            .done(function(xhr){
                if(xhr.success){
                    self.onSuccess(xhr.message);
                }else{
                    self.onFail(xhr.message);
                }
            })
            .fail(function(){
                self.onFail(window.translate('observation.submit.error'));
            })
            .always(function(){
                self.$btnSubmit.removeAttr('disabled');
            });

        }else{
			var formObject = this.$form[0];
			var scrolled = false;
			$( ':input[required]', formObject ).each( function () {
				var elem = $(this);
				if ( this.value.trim() !== '' ) {
					elem.removeClass('required');
				}else{
					elem.addClass('required');
					elem.after(
						$("<div/>")
						.addClass('message-input-error')
						.append(window.translate('contact.submit.errorFields') + ' <strong>' + elem.parent().find('label').html() + '</strong>')
					);
					if(scrolled === false){
						$('html, body').animate({
							scrollTop: elem.offset().top
						}, 1000);
						scrolled = true;
					}
				}
			});

            self.onFail(window.translate('observation.submit.invalid'));
        }
    }

    onSuccess(message){
        this.$form.hide();
        this.$infoContainer
        .html('');
		
		$("header").css('position','fixed').css('width','100%').css('top','0').css('z-index','10000');

        let $successMessage = $('<div/>')
        .addClass('message message-success')
		.append(
			$('<a/>')
			.attr('href','/')
			.addClass('menu-form-link')
			.append(
				$('<img/>')
				.attr('src','/build/img/icons/picto_menu.svg')
			)
		)
        .append(
            $('<h1/>')
            .addClass('message-title')
            .append(window.translate('observation.submit.success.title'))
        )
        .append(
            $('<div/>')
            .addClass('message-block')
            .append(
                $('<p/>')
                .append(window.translate('observation.submit.success.block1.sentence'))
            )
            .append(
                $('<button/>')
                .addClass('btn btn-link btn-back')
                .append(
                    $('<i/>')
                    .addClass('icon icon-arrow-left')
                )
                .append(window.translate('observation.form.back'))
                .on('click',this.onClickBack)
            )
        )
        .append(
            $('<div/>')
            .addClass('message-block')
            .append(
                $('<h3/>')
                .addClass('message-block-title')
                .append(window.translate('observation.submit.success.block2.title'))
            )
            .append(
                $('<p/>')
                .append(window.translate('observation.submit.success.block2.sentence'))
            )
            .append(
                $('<ul/>')
                .append(
                    $('<li/>')
                    .append(
                        $('<a/>')
                        .attr('href',window.App.url('identity'))
                        .addClass('btn btn-link')
                        .append(
                            $('<i class="icon icon-arrow-right"></i>')
                        )
                        .append(window.translate('observation.submit.success.block2.linkIdentity'))
                    )
                )
                .append(
                    $('<li/>')
                    .append(
                        $('<a/>')
                        .attr('href',window.App.url('identify'))
                        .addClass('btn btn-link')
                        .append(
                            $('<i class="icon icon-arrow-right"></i>')
                        )
                        .append(window.translate('observation.submit.success.block2.linkIdentify'))
                    )
                )
                .append(
                    $('<li/>')
                    .append(
                        $('<a/>')
                        .attr('href',window.App.url('observe'))
                        .addClass('btn btn-link')
                        .append(
                            $('<i class="icon icon-arrow-right"></i>')
                        )
                        .append(window.translate('observation.submit.success.block2.linkObserve'))
                    )
                )
            )
        )
        .append(
            $('<div/>')
            .addClass('message-block')
            .append(
                $('<p/>')
                .append(window.translate('observation.submit.success.block3.sentence'))
            )
            .append(
                $('<div/>')
                .addClass('social-list')
                .append(
                    $('<div/>')
                    .addClass('social-list-item')
                    .append(
                        $('<a/>')
                        .attr('target','_blank')
                        .attr('href',"https://www.facebook.com/BouquetinPyrenees")
                        .append(
                            $('<img/>')
                            .attr('src',"/build/img/socials/petit_facebook.svg")
                            .attr('alt',"Facebook")
                            .attr('title',"Facebook")
                        )
                    )
                )
                .append(
                    $('<div/>')
                    .addClass('social-list-item')
                    .append(
                        $('<a/>')
                        .attr('target','_blank')
                        .attr('href',"https://www.instagram.com/parcpyreneesariegeoises/")
                        .append(
                            $('<img/>')
                            .attr('src',"/build/img/socials/petit_insta.svg")
                            .attr('alt',"Instagram")
                            .attr('title',"Instagram")
                        )
                    )
                )
                .append(
                    $('<div/>')
                    .addClass('social-list-item')
                    .append(
                        $('<a/>')
                        .attr('target','_blank')
                        .attr('href',"https://www.youtube.com/channel/UCNxeeSky6Y3i4AAb4e6ciGA")
                        .append(
                            $('<img/>')
                            .attr('src',"/build/img/socials/petit_youtube.svg")
                            .attr('alt',"Youtube")
                            .attr('title',"Youtube")
                        )
                    )
                )

            )
        )


        this.$infoContainer.append($successMessage);

    }

    onFail(message){
        this.$infoContainer
        .html('')
        .append(
            $('<div/>')
            .addClass('message message-error')
            .append(message)
        );
    }

    show(){
        this.overlay.show();

		$("html").css('overflow','hidden');
		$("header").css('position','fixed').css('width','100%').css('top','0');
    }

    hide(){
        this.overlay.hide();
		
		$("html").css('overflow','auto');   
		$("header").css('position','unset').css('width','unset').css('top','unset').css('z-index','100');
    }

	onClickStep3(){
		$("input[type=radio][name=how]").change(function() {
			$(this).parents('.row').find('.form-group-radio.checked').removeClass('checked');
			$(this).parents('.form-group-radio').addClass('checked');
		});
	}

}

module.exports = Form;
