
class FileDropZone {

    defaultOptions() {
        return {
            'uniqid': window.uniqid(),


            'acceptedMimeTypes': [
                "text/plain",
                "image/*",
                "video/*",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.oasis.opendocument.text",
            ],
            '$label': "Choose or drop files",
            'inputName': 'files[]'
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onInputChange = this.onInputChange.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.id = "fileDropZoneContainer"+this.settings.uniqid;
        this.inputId = "fileDropZoneInput"+this.settings.uniqid;

        this.$container = $('<div/>')
        .addClass('filedropzone-wrapper')
        .attr('id',this.id);


        this.initialize();

    }

    initialize() {

        this.$dropZoneContainer = $('<div/>')
        .addClass('filedropzone-container');

        this.$dropZone = $('<div/>')
        .addClass('filedropzone');


        let accept = this.settings.acceptedMimeTypes.join(', ');

        this.$input = $('<input/>')
        .attr('type','file')
        .attr('name',this.settings.inputName)
        .attr('id',this.inputId)
        .attr('multiple','multiple')
        .attr('accept',accept)
        .addClass('filedropzone-input')
        .hide();

        this.$label = $('<label/>')
        .attr('for',this.inputId)
        .append(this.settings.$label);

        this.$previewContainer = $('<div/>')
        .addClass('filedropzone-preview-container');

        this.$dropZone
        .append(this.$input)
        .append(this.$label);

        this.$dropZoneContainer.append(this.$dropZone);

        this.$container
        .append(this.$previewContainer)
        .append(this.$dropZoneContainer)


        this.filesBuffer = null;

        this.bindEvents();
    }

    reset(){
        this.filesBuffer = new DataTransfer();//reset buffer
        this.updateFileBuffer([]);
    }

    bindEvents(){
        var self = this;

        this.$input.on('change',this.onInputChange);
        this.$dropZone.on('dragenter', this.onDragEnter);
        this.$dropZone.on('dragleave', this.onDragLeave);
        this.$dropZone.on('dragover', this.onDragOver);
        this.$dropZone.on('drop', this.onDrop);
    }


    onClickRemove($item){

        let nameToRemove = $item.data('name');

        let files = this.$input.get(0).files;


        this.filesBuffer = new DataTransfer();//reset buffer
        let filesToKeep = [];
        for(var i in files){
            let file = files[i];
            if(file instanceof  File){
                if(file.name != nameToRemove)
                filesToKeep.push(file);
            }
        }

        this.updateFileBuffer(filesToKeep);
    }


    onInputChange(event){
        event.stopPropagation();
        event.preventDefault();

        let files = this.$input.get(0).files;

        this.updateFileBuffer(files);
    }

    onDragEnter(event){
        event.stopPropagation();
        event.preventDefault();

        this.$dropZone.addClass('active');
    }

    onDragLeave(event){
        event.stopPropagation();
        event.preventDefault();

        this.$dropZone.removeClass('active');
    }

    onDragOver(event){
        event.stopPropagation();
        event.preventDefault();

        this.$dropZone.addClass('active');
    }

    onDrop(event){
        event.stopPropagation();
        event.preventDefault();

        this.$dropZone.removeClass('active');

        let nativeEvent = event.originalEvent;
        let dragData = nativeEvent.dataTransfer;
        console.log("onDrop",nativeEvent,dragData);
        let files = dragData.files;

        let hasError = false;
        for(var i in files){
            let file = files[i];
            if(file instanceof  File){
                if(!this.isValidFile(file)){
                    hasError = true;
                }
            }

        }

        if(!hasError){
            this.updateFileBuffer(files);
        }else{
            //@todo show error
        }
    }

    isValidFile(file){
        let type = file.type;

        let availablesTypes = this.settings.acceptedMimeTypes;
        for(var i in availablesTypes){
            let mimeType = availablesTypes[i];
            let regex = new RegExp(mimeType);
            if(regex.test(type)){
                return true;
            }
        }

        return false;
    }


    updateFileBuffer(files){


        if(!this.filesBuffer){
            this.filesBuffer = new DataTransfer();
        }

        for(var i in files){
            let file = files[i];
            if(file instanceof  File){
                this.filesBuffer.items.add(file);
            }
        }


        this.$input.get(0).files = this.filesBuffer.files;


        this.updatePreview();
    }

    updatePreview(){


        this.$previewContainer.html('');


        let $imageList = $('<div/>')
        .addClass('image-list')
		.hide();

        let $fileList = $('<ul/>')
        .addClass('file-list')
		.hide();


        this.$previewContainer
        .append($imageList)
        .append($fileList);

        let files = this.$input.get(0).files;

        for(var i in files){
            var file = files[i];

            if(file instanceof  File){
                let type = file.type;

                if (type.match(/^image/)) {
                    let $preview = this.generatePreviewImage(file);

                    $imageList
                    .append(
                        $('<div/>')
                        .addClass('image-list-item')
                        .append($preview)
                    )
					.show();
                }else{
                    let $preview = this.generatePreviewFile(file);


                    $fileList
                    .append(
                        $('<li/>')
                        .addClass('file-list-item')
                        .append($preview)
                    )
					.show();
                }

            }
        }
    }

    generatePreviewImage(file){
        var self = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);


        let $btnRemove = $('<button/>')
        .attr('data-name',file.name)
        .addClass('btn btn-text text-orange btn-preview-remove')
        .addClass('float-end')
        .append("&#215;")
        .on('click',function(event){
            event.stopPropagation();
            event.preventDefault();
            let $btn = $(this);
            self.onClickRemove($btn)
        })


        let $img = $('<img />')
        let $preview = $('<div/>')
        .addClass('filedropzone-preview filedropzone-preview-image')
        .append($btnRemove)
        .append(
            $('<figure/>')
            .append($img)
        )
		.append(
			$('<div/>')
			.addClass('img-legend')
			.append(file.name)
		)

        reader.onloadend = function(){
            $img.attr('src',reader.result);
        }


        return $preview;

    }

    generatePreviewFile(file){
		var self = this;

        let $btnRemove = $('<button/>')
        .attr('data-name',file.name)
        .addClass('btn btn-text text-orange btn-preview-remove')
        .addClass('float-end')
        .append("&#215;")
        .on('click',function(event){
            event.stopPropagation();
            event.preventDefault();
            let $btn = $(this);
            self.onClickRemove($btn)
        })

        let $preview = $('<div/>')
        .addClass('filedropzone-preview filedropzone-preview-file')
        .append(
            $('<i/>')
            .addClass('icon icon-file')
        )
        .append(
            $('<span/>')
            .addClass('preview-file-name')
            .append(file.name)
        )
        .append($btnRemove)


        return $preview;
    }


    getInput(){
        return this.$input;
    }


    getContainer(){
        return this.$container;
    }
}

module.exports = FileDropZone;
