
class Overlay {

    defaultOptions() {
        return {
            'outofscopeSelector': '#outofscope'
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.initialize();

        this.coordinates = null;
    }

    initialize() {
        var self = this;

        this.build();

        $(this.settings.outofscopeSelector).append(this.$container)
    }

    build(){
        this.$container = $('<div/>')
        .addClass('overlay');
    }

    setContent($content){
        this
        .$container.html('')
        .append($content);
    }

    append($content){
        this
        .append($content);
    }

    prepend($content){
        this
        .prepend($content);
    }

    show(){
        this.$container.addClass('show');
    }

    hide(){
        this.$container.removeClass('show');
    }

    dispose(){
        this.$container.remove();
    }
}

module.exports = Overlay;
