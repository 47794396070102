
const ObservationForm = require('app/component/observation/Form');
const ObservationMap = require('app/component/observation/Map');

class Home {

	defaultOptions() {
		return {
			'$container': null,
		};
	}

	constructor(options) {
		this.settings = $.extend(this.defaultOptions(), options);
		this.googleMapAvailable = false;
		this.$container = this.settings.$container;
		
		this.initialize();
	}

	initialize() {
		var self = this;

		$('body').on('click','.btn-form-observation',function(){
			$(".not-enough-zoom").remove();
			var $btn = $(this);

			if($btn.hasClass('disabled')){
				self.onZoomNotSupported();
			}else{
				self.onClickBtnFormObservation($btn);
			}
		});

		$("body").on("click",".close-not-enough",function(){
			$(".not-enough-zoom").remove();
		});

		self.initMapObservation();
	}

	onZoomNotSupported(){
		let $div = $("<div/>")
		.addClass('not-enough-zoom')
		.append(
			$("<i/>")
			.addClass('icon icon-cross close-not-enough')
		)
		.append(
			$("<i/>")
			.addClass('icon icon-eye')
		)
		.append(
			$("<h2/>")
			.append(window.translate('home.map.zoom.title'))
		)
		.append(
			$("<p/>")
			.append(window.translate('home.map.zoom.p1'))
		)
		.append(
			$("<p/>")
			.addClass('bg-brown')
			.append('<i class="icon icon-zoom"></i>' + window.translate('home.map.zoom.p2'))
		);
		$("body").prepend($div);
	}

	initMapObservation(){
		new ObservationMap({
			'$container': $('#map-container')
		});
	}

	onClickBtnFormObservation($btn){
		let coordinates = $btn.data('coordinates');
		if(!coordinates){
			console.warning("No coordinates defined");
		}
		if(!this.form){
			this.form = new ObservationForm({

			})
		}

		this.form.setCoordinates(coordinates);
		this.form.show();
	}
}

module.exports = Home;
