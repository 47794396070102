

class Form {

	defaultOptions() {
		return {
			'$form': null,
			'formSelector': '.form',
			'before':  null,//function(form){},
			'done':  null,//function(form,response, textStatus, jqXhr){},
			'error': null,//function(form,response, textStatus, jqXhr){},
			'after': null,//function(form){},

			'resetOnHidden': true,
		};
	}

	constructor(options) {
		this.settings = $.extend(this.defaultOptions(), options);

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onShown = this.onShown.bind(this);
		this.onHidden = this.onHidden.bind(this);
		this.onInvalid = this.onInvalid.bind(this);

		this.$form = this.settings.$form;
		if(this.$form === null || this.$form.length <= 0){
			window.notify("missing $form settings for app/ui/Form");
		}

		this.$btnSubmit = $('.btn-submit',this.$form);
		this.$formInfo = $('.form-info[data-for="'+this.$form.attr('id')+'"]');

		this.initialize();
	}

	initialize(){
		var self = this;

		this.$btnSubmit.on('click',this.onSubmit);
		this.$form.on('submit',this.onSubmit);
		this.$form.on('keyup change paste', 'input, select, textarea',this.onChange);
	}

	onShown(){
		this.visible = true;
	}

	onHidden(){
		this.visible = false;
		if(this.settings.resetOnHidden){
			this.reset();
		}
	}

	reset(){
		if(this.$formInfo.length>0){
			this.$formInfo.hide().html('').removeClass('success').removeClass('error');
		}
		this.$form
		.removeClass('was-validated')
		.show();
	}

	isValid(){
		var formObject = this.$form[0];
		var validity = formObject.checkValidity();
		this.$form.addClass('was-validated');

		if(validity === false){
			return false;
		}
		return true;
	}

	onInvalid(){
		$(".message-input-error").remove();
		$(".required").removeClass('required');
		var formObject = this.$form[0];
		var scrolled = false;
		$( ':input[required]', formObject ).each( function () {
			var elem = $(this);
			if ( this.value.trim() !== '' ) {
				elem.removeClass('required');
			}else{
				elem.addClass('required');
				elem.after(
					$("<div/>")
					.addClass('message-input-error')
					.append(window.translate('contact.submit.errorFields') + ' <strong>' + elem.parent().find('label').html() + '</strong>')
				);
				if(scrolled === false){
					$('html, body').animate({
						scrollTop: elem.offset().top
					}, 1000);
					scrolled = true;
				}
			}
		});
	}

	submit(){
		this.$form.trigger('submit');
	}

	onSubmit(event){
		var self = this;
		event.stopPropagation();
		event.preventDefault();

		if(this.$formInfo.length>0){
			this.$formInfo.hide().html('').removeClass('success').removeClass('error');
		}

		if(!this.isValid()){
			this.onInvalid();
			return;
		}

		this.onBeforeSubmit();
	}

	onBeforeSubmit(){
		if(this.settings.before){
			this.settings.before(this);
		}

		return this.doSubmit();
	}

	onChange(event){
		this.$form.removeClass('was-validated');

		//reset customValidity message in order to be able to call isValid again
		$('input,textarea,select',this.$form).each(function(){
			let $element = $(this);
			$element[0].setCustomValidity('');
		});
	}

	onDone(response, textStatus, jqXhr){
		if(this.settings.done){
			this.settings.done(this, response, textStatus, jqXhr);
		}
	}

	onFail(response, textStatus, jqXhr){
		if(this.settings.error){
			this.settings.error(this, response, textStatus, jqXhr);
		}
	}

	onAfter(){
		if(this.settings.after){
			this.settings.after(this);
		}
	}

	getData(hasFormData){
		if(hasFormData){
			return new FormData(this.$form[0]);
		}
		return this.$form.serializeArray();
	}

	doSubmit(){
		var self = this;

		let url = this.$form.attr('action');
		let method = this.$form.attr('method');
		let data = this.getData();

		$.ajax({
			'url': url,
			'method': method,
			'data': data,
			//@todo improvement handle multipart/data (if necessary)
		})
		.done(function(response,textStatus,jqXhr){
		console.log("on ajax done");
			self.onDone(response, textStatus, jqXhr);
		})
		.fail(function(jqXhr, textStatus, errorThrown){
			let response = jqXhr.responseText;
			self.onFail(response, textStatus, jqXhr);
		})
		.always(function(){
			self.onAfter();
		});
	}

}

module.exports = Form;
