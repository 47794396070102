

class Map{
	defaultOptions() {
		return {
			'$container': null,
			'zoom': 12,
			'minimalZoom': 12,
			'center':{
				'lat': 42.782502, 
				'lng': 1.388199 
			},
			'mapId': '5e2e41dc2c8f6f9',
		};
	}

	constructor(options) {
		this.settings = $.extend(this.defaultOptions(), options);

		this.$container = this.settings.$container;
		this.initialize();

	}

	initialize(){
		var self = this;

        window.App.on('onGoogleMapApiLoaded',function(){
            self.initMap();
        });

		self.onClickLayer();

		

		if($(window).width() <= 990){
			$("body").on("click",".geolocation-map",function(){
				self.setGeolocation();
			});
		}
	}

	
	setGeolocation(){
		var self = this;
		if ( navigator.geolocation ) { 
		
			//navigator.geolocation.getCurrentPosition( self.setCurrentPosition, self.positionError, { 
			navigator.geolocation.getCurrentPosition( 
				function(position){
					$('.btn-form-observation').data('coordinates', position.coords.latitude + ',' + position.coords.longitude);

					self.map.setCenter({lat: position.coords.latitude, lng: position.coords.longitude}, self.settings.zoom);

					if(self.mapLeaflet !== undefined){
						self.mapLeaflet.panTo([position.coords.latitude, position.coords.longitude]);
						self.map.setZoom(self.settings.zoom);
					}
				},
				function(){

				},
				{ 
					enableHighAccuracy: true, 
					timeout: 15000, 
					maximumAge: 0 
				}
			);
		} 
	}

	onClickLayer(){
		var self = this;

		$(".btn-change-map").click(function(){
			self.initLeaflet();
		});
	}

	initMap(){
		var self = this;
		let mapElement = this.$container.get(0);
		self.map = new google.maps.Map(mapElement, this.getGoogleMapOptions());

		google.maps.event.addListener(self.map, 'center_changed', function() { 
			$('.btn-form-observation').data('coordinates', self.map.getCenter().lat() + ',' + self.map.getCenter().lng());
		});

		google.maps.event.addListener(self.map, 'click', function(event){
			self.map.setCenter(event.latLng);
		});

		google.maps.event.addListener(self.map, 'zoom_changed', function() {
			let zoomLevel = self.map.getZoom();
			if (zoomLevel < self.settings.minimalZoom) {
				$('.btn-form-observation').addClass('disabled');
			} else {
				$('.btn-form-observation').removeClass('disabled');
			}
		});
	}

	getGoogleMapOptions(){
		return {
			'center': this.settings.center,
			'zoom': this.settings.zoom,
			'mapId': this.settings.mapId,
			'fullscreenControl': false,
			'mapTypeControl': false,
			'streetViewControl': false,
		};
	}

	initLeaflet(){
		var self = this;
		let mapElement = this.$container.get(0);

		if(self.mapLeaflet !== undefined){
			self.mapLeaflet.remove();
			delete self.mapLeaflet;
		}else{
			Gp.Services.getConfig({
				apiKey : "essentiels",
				onSuccess : function(){
					self.mapLeaflet = L.map(mapElement).setView([self.map.getCenter().lat(), self.map.getCenter().lng() ], self.map.getZoom());
					var lyr = L.geoportalLayer.WMTS({
						// layer: "ORTHOIMAGERY.ORTHOPHOTOS",
						layer: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2",
					}, {
						opacity: 1
					});
					lyr.addTo(self.mapLeaflet);

					self.mapLeaflet.on('click', function(e) {
						self.map.setCenter(e.latlng);
						self.mapLeaflet.panTo(e.latlng);
					});

					self.mapLeaflet.on('zoomend', function(e) {
						self.map.setZoom(e.target._zoom);
						console.log('Zoom : ' + e.target._zoom);
					});
				},
				onFailure: (e) => {console.log(e)}
			});
		}
	}

}

module.exports = Map;