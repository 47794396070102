
class Timeline {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);

        this.$container = this.settings.$container;
        if(!this.$container){
            console.error("Timeline.$container is not defined");
        }

        this.$btnNext = $('.btn-paginator-next',this.$container);
        this.$btnPrevious = $('.btn-paginator-previous',this.$container);

        this.initialize();
    }

    initialize() {
        var self = this;

        let $links = $('.timeline-link',this.$container);
        $links.on('click',function(event){
            event.preventDefault();
            event.stopPropagation();

            self.onClickLink($(this));
        })

        this.$btnNext.on('click',this.onClickNext);
        this.$btnPrevious.on('click',this.onClickPrevious);

        self.setMobileSwiper();
    }

    onClickLink($link){
        let target = $link.data('target');

        let $pane = $(target,this.$container);

        if($pane.length > 0){
            $('.timeline-link',this.$container).removeClass('active');
            $('.timeline-pane',this.$container).removeClass('show');

            $link.addClass('active');
            $pane.addClass('show');

            let index = parseInt($link.data('index'));
            let previous = index-1;
            let $previousPane = $('.timeline-pane[data-index="'+previous+'"]',this.$container);
            let next = index+1;
            let $nextPane = $('.timeline-pane[data-index="'+next+'"]',this.$container);

            this.$btnPrevious.attr('disabled','disabled');
            this.$btnNext.attr('disabled','disabled');
            if($previousPane.length > 0){
                this.$btnPrevious.removeAttr('disabled');
            }if($nextPane.length > 0){
                this.$btnNext.removeAttr('disabled');
            }
        }
    }

    getCurrentIndex(){
        let $link = $('.timeline-link.active',this.$container);
        if($link.length <= 0){
            return 0;
        }
        return parseInt($link.data('index'));
    }

    getNextLink(){
        let currentIndex = this.getCurrentIndex();

        let index = currentIndex+1;

        let $link = $('.timeline-link[data-index="'+index+'"]',this.$container);
        if($link.length <= 0){
            return null;
        }
        return $link;
    }

    getPreviousLink(){
        let currentIndex = this.getCurrentIndex();
        let index = currentIndex-1;

        let $link = $('.timeline-link[data-index="'+index+'"]',this.$container);
        if($link.length <= 0){
            return null;
        }
        return $link;
    }

    onClickNext(event){
        event.preventDefault();
        event.stopPropagation();

        let $next = this.getNextLink();
        if($next){
            $next.trigger('click');
        }

        let $futureNext = this.getNextLink();

        if(!$futureNext){
            this.$btnNext.attr('disabled','disabled');
        }else{
            this.$btnNext.removeAttr('disabled');
        }

        this.$btnPrevious.removeAttr('disabled');
		
		$('html, body').animate({
			scrollTop: this.$container.offset().top
		}, 500);
    }

    onClickPrevious(event){
        event.preventDefault();
        event.stopPropagation();

        let $previous = this.getPreviousLink();
        if($previous){
            $previous.trigger('click');
        }

        let $futurePrevious = this.getPreviousLink();
        if(!$futurePrevious){
            this.$btnPrevious.attr('disabled','disabled');
        }else{
            this.$btnPrevious.removeAttr('disabled');
        }
        this.$btnNext.removeAttr('disabled');
		
		$('html, body').animate({
			scrollTop: this.$container.offset().top
		}, 800);
    }

    setMobileSwiper(){
        if ($(".timeline-link-list").length > 0) {
            new Swiper('.timeline-link-list', {
                speed: 400,
                spaceBetween: 0,
                slidesPerView: 1.3,
                freeMode: true,
                watchSlidesProgress: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.block-testimonial-swiper-button-next',
                    prevEl: '.block-testimonial-swiper-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 2.3,
                    },
                    768: {
                        slidesPerView: 3.3,
                    },
                    991: {
                        slidesPerView: 4,
                    },
                }
            });
        }
    }
}

module.exports = Timeline;
