
const Form = require('app/ui/Form');

class Contact extends Form {

    defaultOptions(){
        var self = this;
        var options = {
            //
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        super.initialize();
    }

    onDone(response, textStatus, jqXhr){
        console.log("on done",this,this.$formInfo);
        this.$formInfo.append(response.message);
        if(response.success){
            this.$form.hide();
            this.$formInfo.addClass('success');
			$(".view-contact").html('');
			$(".view-contact")
			.append(
				$("<h1/>").append(window.translate('contact.validation.title'))
			)
			.append(
				$("<p/>").append(window.translate('contact.validation.message.p1'))
			)
			.append(
				$("<p/>").append(window.translate('contact.validation.message.p2'))
			)
			.append(
				$("<div/>")
				.addClass('button-return-zone')
				.append(
					$("<a/>")
					.addClass('btn btn-orange')
					.attr('href',window.App.url('contact'))
					.append(window.translate('contact.validation.button.label'))
				)
			)
			.append(
				$("<p/>").append(window.translate('contact.validation.message.p3'))
			)
			.append(
				$("<p/>").append(window.translate('contact.validation.message.p4'))
			)
			.append(
				$("<p/>").append(window.translate('contact.validation.message.p5'))
			);
        }else{
            this.$formInfo.addClass('error');
        }
        this.$formInfo.show();
    }

    onFail(response, textStatus, jqXhr){

        this.$formInfo.append(response.message);
        this.$formInfo.show().addClass('error');
    }

}

module.exports = Contact;
