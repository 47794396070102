require('app/common');

const Menu = require('app/component/Menu');

class Application {

    defaultOptions() {
        return {
            'routes': [],
            'translations': [],
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        window.App = this;//set singleton instance

        this.modals = {};
        this.googleMapAvailable = false;
        this.events = {};

        this.initialize();
    }

    get(key) {
        return this.settings[key] ?? null;
    }

    set(key, value) {
        this.settings[key] = value;
        return this;
    }

    url(routeName, params) {
        let route = this.settings.routes[routeName] ?? null;
        if (route === null) {
            return window.notify('Route ' + routeName + ' do not exist', 'error');
        }

        let queryParams = {};
        if (params) {
            let paramsKeys = Object.keys(params);
            for (var i in paramsKeys) {
                let key = paramsKeys[i];
                let value = params[key];

                let targetKey = "__" + key.toUpperCase() + "__";

                if (route.includes(targetKey)) {
                    route = route.replace(targetKey, value);
                } else {
                    queryParams[key] = value;
                }
            }
        }

        let queryParamsString = window.objectToUrlParameters(queryParams);

        return route + queryParamsString;
    }

    initialize() {
        this.initMenu();
    }

    translate(key,data){

        let translations = this.settings.translations;
        if(!translations[key]){
            return key;
        }

        let rawTranslation = translations[key];
        let message = rawTranslation;
        if(data && data.length){
            let dataKeys = Object.keys(data);
            for(var i in dataKeys){
                let key = dataKeys[i];
                let value = data[key];
                message.replace(key,value);
            }
        }
        return message;
    }

    notify(message, level, data, delay) {
        if (!data) {
            data = null;
        }

        switch (level) {
            case 'error':
            case 'err':
            case 'danger':
                data ? console.error(message, data) : console.error(message);
                break;

            case 'warning':
            case 'warn':
                data ? console.warn(message, data) : console.warn(message);
                break;

            case 'success':
            case 'info':
            default:
                //no need to flood the console with success or info
                //data ? console.log(message,data) : console.log(message);
                break;
        }

        //@todo develop a way to show notification to user
    }

    getMainWindow() {
        if (window.inIframe() && window.parent) {
            return window.parent;
        }
        return window;
    }

    getMainDocument() {
        return this.getMainWindow().document;
    }

    getTopDocument() {
        return this.getMainDocument();
    }

	initMenu(){
        this.menu = new Menu({});
        
	}

    onGoogleMapApiLoaded(){
        this.googleMapAvailable = true;

        //console.log("onGoogleMapApiLoaded");

        if(this.events['onGoogleMapApiLoaded']){
            for(var i in this.events['onGoogleMapApiLoaded']){
                let callback = this.events['onGoogleMapApiLoaded'][i];
                callback();
            }
        }
    }
    
    isGoogleMapAvailable(){
        return this.googleMapAvailable;
    }

    on(eventName,callback){

        if(!this.events[eventName]){
            this.events[eventName] = [];
        }

        this.events[eventName].push(callback);
    }
}

module.exports = Application;
