
class Menu {

    defaultOptions() {
        return {
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.initialize();
    }

    initialize() {
        var self = this;

        this.$menuLink = $('.menu-link');

        this.$menuLink.on("click",function(event){
			$("header").toggleClass('menu-opened');
			if ($(window).width() < 991) {
				$("body").toggleClass('overflow-hidden');
			}
        });
		
        this.$menuLang = $("ul.langs");

        this.$menuLang.on("click",function(event){
            self.$menuLang.toggleClass('opened');
        });
    }

}

module.exports = Menu;
